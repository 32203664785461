<div class="row"
     *ngIf="game && board && bag && userId">
  <div class="col-md-12 mt-4">
    <div class="card">
      <div class="card-body title-body">
        <!-- game settings -->
        <div class="row"
             *ngIf="'WAITING' === currentStatus">
          <!-- language -->
          <div class="col-md-3 col-sm-6 col-12">
            <div class="info-box">
              <span class="info-box-icon bg-info"><i class="fas fa-flag"></i></span>
              <div class="info-box-content">
                <span class="info-box-text">{{ 'game.bag.language' | translate }}</span>
                <span class="info-box-number">{{ 'game.language.' + bag.language | translate }}</span>
              </div>
            </div>
          </div>
          <!-- player count -->
          <div class="col-md-3 col-sm-6 col-12">
            <div class="info-box">
              <span class="info-box-icon bg-info"><i class="fas fa-users"></i></span>
              <div class="info-box-content">
                <span class="info-box-text">{{ 'game.playerCount' | translate }}</span>
                <span class="info-box-number">{{ game.expectedPlayerCount }}</span>
              </div>
            </div>
          </div>
          <!-- remaining duration -->
          <div class="col-md-3 col-sm-6 col-12">
            <div class="info-box">
              <span class="info-box-icon bg-info"><i class="far fa-hourglass"></i></span>
              <div class="info-box-content">
                <span class="info-box-text">{{ 'game.duration' | translate }}</span>
                <span class="info-box-number">{{ 'game.duration.seconds' | translate: { '0': game.duration } }}</span>
              </div>
            </div>
          </div>
          <!-- board size -->
          <div class="col-md-3 col-sm-6 col-12">
            <div class="info-box">
              <span class="info-box-icon bg-info"><i class="fas fa-chess-board fa-fw"></i></span>
              <div class="info-box-content">
                <span class="info-box-text">{{ 'game.board.size' | translate }}</span>
                <span class="info-box-number">{{ board.rowSize + 'X' + board.columnSize }}</span>
              </div>
            </div>
          </div>
        </div>
        <!-- waiting players -->
        <div class="row"
           *ngIf="'WAITING' === currentStatus">
          <div class="col-md-3 ml-1"
               *ngFor="let player of players">
            <div class="card card-outline"
                 [ngClass]="player.userId > 0 ? 'card-primary' : 'card-warning'">
              <div class="card-body box-profile">
                <div class="text-center">
                  <img class="profile-user-img img-fluid img-circle"
                    src="{{imageResourceURL}}/{{player.userId}}.png"
                    alt="User profile picture">
                </div>
                <h3 class="profile-username text-center">{{ player.username ? player.username : '?' }}</h3>
                <p class="text-muted text-center" *ngIf="player.userId === game.ownerId">{{ 'game.owner' | translate
                  }}</p>
                <p class="text-muted text-center" *ngIf="player.userId !== game.ownerId">{{ 'game.player' |
                  translate }}</p>
              </div>
            </div>
          </div>
          <div class="col-md-1 ml-4">
            <a class="btn btn-app bg-danger"
               *ngIf="game.ownerId !== userId"
               (click)="leaveGame(game.id)">
              <i class="fas fa-thumbs-down"></i> {{ 'game.leave' | translate }}
            </a>
          </div>
        </div>
        <!-- playing players -->
        <div class="row"
           *ngIf="'WAITING' !== currentStatus">
          <div class="col-md-3 ml-1"
               *ngFor="let player of players">
            <div class="info-box border"
                 [ngClass]="{
                   'border-success': winnerPlayer && winnerPlayer.playerNumber === player.playerNumber,
                   'border-secondary': !winnerPlayer && player.playerNumber === currentPlayerNumber,
                   'border-light': !winnerPlayer && player.playerNumber !== currentPlayerNumber
                 }">
              <span class="info-box-icon">
                <img class="img-circle"
                     src="{{imageResourceURL}}/{{player.userId}}.png "
                     alt="User profile picture">
              </span>
              <div class="info-box-content">
                <span class="info-box-text">{{ player.username ? player.username : '?' }}</span>
                <span class="info-box-number">{{ player.score }}</span>
              </div>
              <div class="info-box-content"
                   *ngIf="player.playerNumber === currentPlayerNumber && ('IN_PROGRESS' === currentStatus || 'LAST_ROUND' === currentStatus)">
                <span class="btn btn-app bg-danger">
                  <i class="far fa-hourglass"></i>{{ remainingDuration }}
                </span>
              </div>
              <div class="info-box-content"
                   *ngIf="winnerPlayer && winnerPlayer.playerNumber === player.playerNumber">
                <span class="btn btn-app bg-success">
                  <i class="fas fa-trophy"></i>{{ 'game.winner' | translate }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-7">
    <!-- board -->
    <div class="row"
         *ngIf="virtualBoard">
      <div class="col-md-12">
        <table class="game-table table table-responsive"
               *ngIf="virtualBoard.cells && virtualBoard.cells.length > 0">
          <tbody>
            <tr class="d-flex"
                *ngFor="let r of [].constructor(board.rowSize); index as rowIndex">
              <td class="border p-0"
                  *ngFor="let c of [].constructor(board.columnSize); index as columnIndex"
                  (click)="putTile(getCell(rowIndex, columnIndex))"
                  [style.background-color]="getCell(rowIndex, columnIndex).letter ? '' : getCell(rowIndex, columnIndex).color">
                <div class="cell-div text-center cell"
                     *ngIf="getCell(rowIndex, columnIndex).letter"
                     [ngClass]="{
                       'played-cell': getCell(rowIndex, columnIndex).lastPlayed,
                       'sealed-cell': getCell(rowIndex, columnIndex).tileNumber 
                     }">
                  <span class="cell-letter">{{ getCell(rowIndex, columnIndex).letter }}</span>
                  <span class="cell-score">{{ getCell(rowIndex, columnIndex).value }}</span>
                </div>
                <div class="cell-div d-flex align-items-center"
                     *ngIf="!getCell(rowIndex, columnIndex).letter">
                  <span class="cell-multiplier container-fluid font-weight-bold"
                        *ngIf="!getCell(rowIndex, columnIndex).letter">
                    {{ getCell(rowIndex, columnIndex).wordScoreMultiplier > 1 ? getCell(rowIndex, columnIndex).wordScoreMultiplier + 'xW' : '' }}
                    {{ getCell(rowIndex, columnIndex).letterValueMultiplier > 1 ? getCell(rowIndex, columnIndex).letterValueMultiplier + 'xL' : '' }}
                  </span> 
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- player rack -->
    <div class="row mt-2 mb-4 rack"
         *ngIf="virtualRack">
      <div class="col-md-2 mt-2 text-center">
        <a class="btn btn-app bg-secondary"
           [ngClass]="playerNumber !== currentPlayerNumber ? 'disabled' : ''"
           (click)="exchange()">
          <i class="fas fa-exchange-alt"></i>{{ 'game.exchange' | translate }}
        </a>
      </div>
      <div class="col-md-8 mt-2 pl-0">
        <table class="table table-responsive mb-0">
          <tbody class="d-flex justify-content-center">
            <tr class="d-flex">
              <td class="tile-td"
                  *ngFor="let tile of virtualRack.tiles"
                  (click)="selectTile(tile)">
                <div class="tile-div text-center tile"
                     *ngIf="tile"
                     [ngClass]="{ 'sealed-tile': tile.sealed || tile.selected }">
                  <span class="tile-letter">{{ tile.letter }}</span>
                  <span class="tile-score">{{ tile.value }}</span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-md-2 mt-2 text-center">
        <a class="btn btn-app bg-secondary"
           [ngClass]="playerNumber !== currentPlayerNumber ? 'disabled' : ''"
           (click)="play()">
          <i class="fas fa-play"></i>{{ 'game.play' | translate }}
        </a>
      </div>
    </div>
  </div>
  <div class="col-md-5"
       *ngIf="'IN_PROGRESS' === currentStatus || 'LAST_ROUND' === currentStatus || 'ENDED' === currentStatus">
    <div class="row">
      <div class="col-md-12">
        <div class="card card-outline card-secondary">
          <div class="card-header">
            <h3 class="card-title">{{ 'game' | translate }}</h3>
            <div class="card-tools">
              <span class="info-box-text">
                <i class="fas fa-bell fa-sm"></i>
                {{ 'game.round' | translate }}: {{ currentRoundNumber }}
              </span>
              <span class="info-box-text ml-2">
                <i class="fas fa-font fa-xs"></i>
                {{ 'game.tile.count' | translate }}: {{ remainingTileCount + '/' + this.bag.tileCount }}
              </span>
            </div>
          </div>
          <div class="card-body actions overflow-auto"
               #scrollActions>
            <span class="info-box-text" *ngFor="let actionMessage of actionMessages" [innerHtml]="actionMessage"></span>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card card-outline card-secondary"
             [ngClass]="chats ? 'direct-chat' : ''">
          <div class="card-header">
            <h3 class="card-title">{{ 'game.chat' | translate }}</h3>
          </div>
          <div class="card-body">
            <div class="direct-chat-messages"
                 *ngIf="chats"
                 #scrollMessages>
              <div class="direct-chat-msg"
                   [ngClass]="userId === chat.userId ? 'left' : 'right'"
                   *ngFor="let chat of chats">
                <div class="direct-chat-infos clearfix">
                  <span class="direct-chat-name float-left">{{ chat.username }}</span>
                  <span class="direct-chat-timestamp float-right">{{ chat.createdDate | date:'dd/MM/yyyy HH:mm:ss' }}</span>
                </div>
                <img class="direct-chat-img"
                     src="{{imageResourceURL}}/{{chat.userId}}.png"
                     alt="Message User Image">
                <div class="direct-chat-text">{{ chat.message }}</div>
              </div>
            </div>
            <div class="callout callout-info"
                 *ngIf="!chats">
              <h5><i class="fas fa-info"></i> {{ 'game.chat.no.message' | translate }}</h5>
            </div>
          </div>
          <div class="card-footer"
               *ngIf="'ENDED' !== currentStatus">
            <form action="#" method="post">
              <div class="input-group">
                <input class="form-control"
                       type="text"
                       name="message"
                       placeholder="{{'game.chat.placeholder' | translate}}"
                       [(ngModel)]="message">
                <span class="input-group-append">
                  <button type="submit"
                          class="btn btn-secondary"
                          (click)="sendMessage()">{{ 'game.send' | translate }}
                  </button>
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>