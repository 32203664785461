<nav class="main-header navbar navbar-expand navbar-white navbar-light">
  <!-- Left navbar links -->
  <ul class="navbar-nav">
    <li class="nav-item">
      <a class="nav-link" data-widget="pushmenu" href="#" role="button"><i class="fas fa-bars"></i></a>
    </li>
  </ul>

  <!-- Right navbar links -->
  <ul class="navbar-nav ml-auto" *ngIf="userId !== null">
    <!-- Language Dropdown Menu -->
    <li class="nav-item dropdown">
      <a class="nav-link" data-toggle="dropdown" href="#">
        <i class="fi"
           [ngClass]="'fi-' + getLanguage()"></i>
      </a>
      <div class="dropdown-menu dropdown-menu-right p-0">
        <a href="#"
           class="dropdown-item active"
           (click)="setLanguage('en')">
          <i class="fi fi-us mr-2"></i> English
        </a>
        <a href="#"
           class="dropdown-item"
           (click)="setLanguage('de')">
          <i class="fi fi-de mr-2"></i> German
        </a>
        <a href="#"
           class="dropdown-item"
           (click)="setLanguage('fr')">
          <i class="fi fi-fr mr-2"></i> French
        </a>
        <a href="#"
           class="dropdown-item"
           (click)="setLanguage('nl')">
          <i class="fi fi-nl mr-2"></i> Dutch
        </a>
        <a href="#"
           class="dropdown-item"
           (click)="setLanguage('tr')">
          <i class="fi fi-tr mr-2"></i> Turkish
        </a>
      </div>
    </li>
    <!-- Logout -->
    <li class="nav-item">
      <a class="nav-link" (click)="logout()">
        <i class="fas fa-sign-out-alt"></i>
      </a>
    </li>
  </ul>
</nav>
<!-- /.navbar -->