<aside class="main-sidebar sidebar-dark-primary elevation-4">
  <!-- Brand Logo -->
  <a href="https://www.github.com/ekarakusnl" class="brand-link">
    <img src="assets/images/header-logo.png" alt="Gamecity" class="brand-image img-circle elevation-3"
      style="opacity: .8">
    <span class="brand-text font-weight-light">Gamecity</span>
  </a>

  <!-- Sidebar -->
  <div class="sidebar" *ngIf="userId !== null">
    <!-- Sidebar user (optional) -->
    <div class="user-panel mt-3 pb-3 mb-3 d-flex">
      <div class="image">
        <img src="{{imageResourceURL}}/{{userId}}.png" class="img-circle elevation-2" alt="User Image">
      </div>
      <div class="info">
        <a class="d-block">{{username}}</a>
      </div>
    </div>

    <!-- Sidebar Menu -->
    <nav class="mt-2">
      <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
        <!-- Add icons to the links using the .nav-icon class
               with font-awesome or any other icon font library -->
        <li class="nav-item">
          <a routerLink="/create-game" class="nav-link">
            <i class="nav-icon fas fa-plus-square fa-fw"></i>
            <p>{{ 'menu.create.game' | translate }}</p>
          </a>
        </li>
        <li class="nav-item">
          <a routerLink="/lobby" class="nav-link">
            <i class="nav-icon fas fa-search fa-fw"></i>
            <p>{{ 'menu.lobby' | translate }}</p>
          </a>
        </li>
        <li class="nav-item">
          <a routerLink="/lounge" class="nav-link">
            <i class="nav-icon fas fa-user-alt fa-fw"></i>
            <p>{{ 'menu.lounge' | translate }}</p>
          </a>
        </li>
      </ul>
    </nav>
    <!-- /.sidebar-menu -->
  </div>
  <!-- /.sidebar -->
</aside>